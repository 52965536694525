import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

export const client = sanityClient({
  projectId: "2oqbl3kg",
  dataset: "production",
  useCdn: false
})

const builder = imageUrlBuilder(client)

export const imageUrlFor = image => builder.image(image)
