import useSwr from "swr"
import { getGames } from "../api/sanity"
import { usePlayer } from "./usePlayer"

export const useGames = () => {
  const { player } = usePlayer()
  const { data: games = [], error } = useSwr(player ? "games" : null, () =>
    getGames(player)
  )
  return { games, error }
}
