import React from "react"
import { Layout } from "@staccx/bento"
import { usePlayer } from "../../hooks/usePlayer"
import GroupsPlayer from "./Groups.Player"
import AllGroups from "./AllGroups"

const Groups = () => {
  const { player } = usePlayer()
  return (
    <Layout>
      <GroupsPlayer player={player} />
      <AllGroups />
    </Layout>
  )
}

export default Groups
