import { Text } from "@staccx/bento"
import { css } from "styled-components"

Text.createVariants({
  text: {
    bold: css`
      font-weight: 700;
    `,
    right: css`
      text-align: right;
    `,
    ticket: css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      align-self: end;
    `,
    purchaser: css`
      bottom: 32px;
      text-align: center;
      transform: rotate(-35deg);
      position: absolute;
      font-weight: 700;
    `
  }
})
