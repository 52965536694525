import React from "react"
import { usePlayerGroups } from "../../hooks/useGroups"
import GroupsList from "./Groups.List"

const GroupsPlayer = () => {
  const { groups } = usePlayerGroups()
  return <GroupsList groups={groups} />
}

export default GroupsPlayer
