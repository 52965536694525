import React from "react"
import { Anchor, Box, formatting, Image, Text } from "@staccx/bento"
import { imageUrlFor } from "../../api/client"
import { Link } from "react-router-dom"

const GamesListItem = ({ game }) => {
  return (
    <Anchor as={Link} to={`/${game._id}`}>
      <Box variant="card">
        <Image variant={["game"]} src={imageUrlFor(game.image)} />
        <Box variant={["space"]}>
          <Text variant={["bold"]}>{game.name}</Text>
          <Text variant={["bold"]}>
            {formatting.formatDateDistance(game.starts, undefined, undefined, {
              addSuffix: true
            })}
          </Text>
        </Box>
        <Text>{game?.group?.name}</Text>
        <Box variant="status" status={game.status}>
          {game.status}
        </Box>
      </Box>
    </Anchor>
  )
}

export default GamesListItem
