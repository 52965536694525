import React from "react"
import useSwr from "swr"
import { getGame } from "../../../api/sanity"
import { useParams } from "react-router"
import { Box, Heading, Layout } from "@staccx/bento"
import GamePlayerTickets from "../Game.PlayerTickets"

const GameGrace = () => {
  const { id } = useParams()
  const { data: game, error } = useSwr(`game/${id}`, () => getGame(id))

  return (
    <Box variant="game">
      <Layout variant="flex">
        <Heading level={2} variant="grace">
          Venter på at spillet skal begynne…
        </Heading>
        <GamePlayerTickets game={game} hideReceipt />
        {error && error.message}
      </Layout>
    </Box>
  )
}

export default GameGrace
