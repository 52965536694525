import React from "react"
import { Box, Image, Text } from "@staccx/bento"
import { imageUrlFor } from "../../api/client"
import GameTicketListItem from "./Game.TicketListItem"

const GameEndedPriceItem = ({ price, ticket, game }) => {
  return (
    <Box variant={["card", "big"]}>
      <Image
        variant={["rounded", "price"]}
        src={imageUrlFor(price.image).width(300)}
      />
      <Box variant={["space"]}>
        <Text variant={["bold"]}>{price.name}</Text>
      </Box>
      <Box variant="flex">
        {ticket && <GameTicketListItem game={game} ticket={ticket} />}
      </Box>
    </Box>
  )
}

export default GameEndedPriceItem
