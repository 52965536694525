import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

let setTokenInterceptor

export const registerTokenFetcher = fetchToken => {
  if (setTokenInterceptor) {
    instance.interceptors.request.eject(setTokenInterceptor)
  }

  setTokenInterceptor = instance.interceptors.request.use(async config => {
    const token = await fetchToken()
    config.headers.authorization = `Bearer ${token}`
    return config
  })
}

instance.interceptors.response.use(
  response => response.data,
  error => error
)

export const createPlayer = user => instance.post("/player/create", { user })
export const buyTicket = (player, game) =>
  instance.post("/game/ticket", { player: player._id, game: game._id })
export const openGame = (player, game) =>
  instance.post("game/open", { player: player._id, game: game._id })
export const lockGame = (player, game) =>
  instance.post("game/lock", { player: player._id, game: game._id })
export const startGame = (player, game) =>
  instance.post("game/start", { player: player._id, game: game._id })
export const endGame = (player, game) =>
  instance.post("game/end", { game: game._id, player: player._id })
export const createGame = (options, player) =>
  instance.post("game/create", { ...options, player: player._id })
export const drawRandom = game => instance.post("game/tick", { game: game._id })
export const purchaseTickets = (tickets, player) =>
  instance.post("game/tickets", {
    tickets: tickets.map(t => t._id),
    player: player._id
  })
export const getWinner = id =>
  instance.get("game/winner", {
    params: {
      id
    }
  })
