import React from "react"
import { Heading, ThemeProvider } from "@staccx/bento"
import { Routes } from "react-router-dom"
import { SWRConfig } from "swr"
import { theme } from "./theme"
import PrivateRoute from "./PrivateRoute"
import Games from "./pages/Games/Games"
import Game from "./pages/Game/Game"
import Header from "./components/Header"
import Main from "./components/Main"
import TicketProvider from "./context/TicketProvider"
import Groups from "./pages/Groups/Groups"
import GroupDetail from "./pages/Groups/Group.Detail"

function App() {
  return (
    <SWRConfig
      value={{
        suspense: true,
        onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
          const {
            response: { status }
          } = error
          if (retryCount >= 10) return
          if (status === 404) return
          // retry after 1 seconds
          setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 1000)
        }
      }}
    >
      <ThemeProvider theme={theme}>
        <React.Suspense
          fallback={
            <Heading variant="logo" level={2}>
              Loading user
            </Heading>
          }
        >
          <Header />
        </React.Suspense>
        <React.Suspense
          fallback={
            <Heading variant="logo" level={2}>
              Loading user
            </Heading>
          }
        >
          <Main>
            <TicketProvider>
              <Routes>
                <PrivateRoute path="" element={<Games />} />
                <PrivateRoute path="groups" element={<Groups />} />
                <PrivateRoute path="groups/:id" element={<GroupDetail />} />
                <PrivateRoute path=":id" element={<Game />} />
              </Routes>
            </TicketProvider>
          </Main>
        </React.Suspense>
      </ThemeProvider>
    </SWRConfig>
  )
}

export default App
