import React from "react"
import { Anchor, Box, Image, Text } from "@staccx/bento"
import { imageUrlFor } from "../../api/client"

const GamePriceItem = ({ price }) => {
  return (
    <Anchor href={price.url} target="_blank" rel="noreferrer noopener">
      <Box variant={["card", "small"]}>
        <Image
          variant={["rounded", "price"]}
          src={imageUrlFor(price?.image).width(300)}
        />
        <Box variant={["space"]}>
          <Text variant={["bold"]}>{price.name}</Text>
        </Box>
      </Box>
    </Anchor>
  )
}

export default GamePriceItem
