import React from "react"
import { Box } from "@staccx/bento"
import GroupsItem from "./Groups.Item"

const GroupsList = ({ groups }) => {
  return (
    <Box variant="flex">
      {groups.map(group => (
        <GroupsItem key={group._id} group={group} />
      ))}
    </Box>
  )
}

export default GroupsList
