import React from "react"
import { Alert } from "@staccx/bento"

const GameStatus = ({ game }) => {
  const [type, setType] = React.useState(null)
  React.useEffect(() => {
    if (game && game.status) {
      switch (game.status) {
        case "draft":
          setType("info")
          break
        case "open":
          setType("success")
          break
        case "locked":
          setType("warning")
          break
        case "started":
          setType("success")
          break
        case "ended":
        default:
          setType("warning")
          break
      }
    }
  }, [game])

  if (!type) {
    return null
  }

  return <Alert type={type}>{game.status}</Alert>
}

export default GameStatus
