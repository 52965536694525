import useSwr from "swr"
import { getGroup, getGroups } from "../api/sanity"
import { usePlayer } from "./usePlayer"

export const useGroup = (id, player) => {
  const { data: group, error } = useSwr(`group/${id}`, () =>
    getGroup(id, player)
  )
  return { group, error }
}

export const useGroups = player => {
  const { data: groups = [], error } = useSwr("groups", () => getGroups(player))
  return { groups, error }
}

export const usePlayerGroups = () => {
  const { player } = usePlayer()
  const { data: groups = [], error } = useSwr(
    player ? `groups/${player._id}` : null,
    () => getGroups(player)
  )
  return { groups, error }
}
