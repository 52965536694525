import React from "react"
import { Box } from "@staccx/bento"
import GamesListItem from "./Games.List.Item"

const GamesList = ({ games }) => {
  return (
    <Box variant="flex">
      {games.map(game => (
        <GamesListItem game={game} />
      ))}
    </Box>
  )
}

export default GamesList
