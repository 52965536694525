import React from "react"
import { Layout } from "@staccx/bento"
import GroupsListItemSmall from "./Groups.List.Item.Small"
import { useGroups } from "../../hooks/useGroups"

const AllGroups = () => {
  const { groups } = useGroups(null)

  return (
    <Layout as="ul" rowGap="tiny" paddingBottom="medium">
      {groups.map(group => (
        <li key={group._id}>
          <GroupsListItemSmall group={group} />
        </li>
      ))}
    </Layout>
  )
}

export default AllGroups
