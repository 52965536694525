import { Image } from "@staccx/bento"
import { css } from "styled-components"

Image.createVariants({
  image: {
    center: css`
      margin: 0 auto;
    `,
    game: css`
      border-radius: 18px;
      padding: 12px;
    `,
    rounded: css`
      border-radius: 50%;
      padding: 12px;
    `,
    price: css`
      width: 224px;
      object-fit: cover;
      max-height: 224px;
      height: auto;
    `
  }
})
