import { Layout } from "@staccx/bento"
import { css } from "styled-components"

Layout.createVariants({
  container: {
    inline: css`
      display: inline;
    `
  }
})
