import React from "react"
import { Box } from "@staccx/bento"
import GamePriceItem from "./Game.Prices.Item"

const GamePrices = ({ prices }) => {
  return (
    <Box variant={["flex", "horz", "prices", "wrap"]}>
      {prices.map(price => (
        <GamePriceItem price={price} />
      ))}
    </Box>
  )
}

export default GamePrices
