import React from "react"
import { Box } from "@staccx/bento"
import useSwr from "swr"
import { getTickets } from "../../api/sanity"
import GameEndedPriceItem from "./Game.Ended.Price.Item"

const GameEndedResults = ({ game }) => {
  const { data: tickets, error } = useSwr(`tickets/${game._id}`, () =>
    getTickets(game._id)
  )

  return (
    <Box variant="flex">
      <Box variant={["results"]}>
        {tickets
          .filter(t => t.isWinner)
          .map(ticket => (
            <GameEndedPriceItem
              key={ticket._id}
              price={ticket.price}
              game={game}
              ticket={ticket}
            />
          ))}
      </Box>
      {error && error.message}
    </Box>
  )
}

export default GameEndedResults
