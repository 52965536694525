import React from "react"
import { Box, Button, formatting } from "@staccx/bento"
import GameTicketListItem from "./Game.TicketListItem"
import { purchaseTickets } from "../../api/api"
import { mutate } from "swr"
import { useTicket } from "../../context/TicketProvider"

const GameTicketList = ({ game, player }) => {
  const [err, setErr] = React.useState(null)
  const { tickets: ticketsOnHold, clearTickets } = useTicket()

  const handleBuyTickets = () => {
    purchaseTickets(ticketsOnHold, player)
      .then(() => {
        clearTickets()
        mutate(`game/${game._id}`)
      })
      .catch(({ ...err }) => {
        clearTickets()
        setErr(err.response.data)
      })
  }
  return (
    <div>
      <Box variant="flex">
        <Box variant={["tickets"]}>
          {game.tickets.map((ticket, index) => (
            <GameTicketListItem
              game={game}
              key={ticket._id}
              ticket={ticket}
              index={index}
            />
          ))}
        </Box>
      </Box>
      {ticketsOnHold.length > 0 && (
        <Button variant="full" onClick={handleBuyTickets}>
          {`Kjøp "${ticketsOnHold
            .map(t => t.title)
            .join(", ")}" for ${formatting.formatNumber(
            ticketsOnHold.length * game.settings.pricePerTicket
          )}`}
        </Button>
      )}
      {err && err.message}
    </div>
  )
}

export default GameTicketList
