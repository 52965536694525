import React from "react"
import { utils } from "@staccx/bento"
import { usePlayer } from "../hooks/usePlayer"

const Context = React.createContext({})

const TicketProvider = ({ children }) => {
  const [tickets, setTickets] = React.useState([])
  const clearTickets = React.useCallback(() => {
    setTickets([])
  }, [])

  const holdTicket = React.useCallback(
    (ticket, game) => {
      const removeFirst = tickets.length >= game.settings.maxTicketsPerPlayer
      if (tickets.some(t => ticket._id === t._id)) {
        setTickets(prevState => prevState.filter(t => t._id !== ticket._id))
      } else {
        setTickets(prevState => [
          ...prevState.slice(removeFirst ? 1 : 0),
          ticket
        ])
      }
    },
    [tickets]
  )

  return (
    <Context.Provider
      value={{
        holdTicket,
        clearTickets,
        tickets
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useTicket = () => React.useContext(Context)

export const usePlayerTickets = game => {
  const { player } = usePlayer()
  return React.useMemo(() => {
    if (game && player) {
      const [mine, theirs] = utils.partition(
        game.tickets,
        ticket => ticket.player && ticket.player._id === player._id
      )
      return [mine, theirs]
    }
    return [[], []]
  }, [game, player])
}

export default TicketProvider
