import React from "react"
import styled from "styled-components"
import { Heading, Text, theming } from "@staccx/bento"
import { Link } from "react-router-dom"
import { usePlayer } from "../hooks/usePlayer"
import { useAuth0 } from "../auth0"

const Header = () => {
  const { player } = usePlayer()
  const { loading } = useAuth0()

  if (loading) {
    return (
      <Heading variant="logo" level={1}>
        Loading
      </Heading>
    )
  }

  return (
    <Wrapper>
      <Link to="/groups">
        <Text>Groups</Text>
      </Link>
      <Link to="/">
        <Heading variant="logo" level={1}>
          Lottery
        </Heading>
      </Link>
      {player && <Text variant="right">{player.name}</Text>}
    </Wrapper>
  )
}

const Wrapper = styled.header`
  width: 100vw;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  background-color: ${theming.color`primary`};
  color: ${theming.color`white`};
  align-items: center;
  padding: ${theming.spacing`large`} 0;
`

export default Header
