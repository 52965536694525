import { theming } from "@staccx/bento"
import { css } from "styled-components"

const remFix = css`
  html {
    font-size: 24px;
    overflow-y: auto;
  }
  body {
    font-size: 0.583333333rem;
    font-family: "Montserrat", sans-serif;
    background-color: ${theming.color`white`};
  }
  h1,
  h4,
  h5,
  h6 {
    color: ${theming.color`text`};
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: ${theming.color("links")};
  }
  span {
    padding: 0 12px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
  }
`

theming.theme.append(
  theming.createGlobal({
    base: theming.cssResets,
    remFix: remFix
  })
)
