import React from "react"
import { usePlayer } from "../../hooks/usePlayer"
import { Box, Heading, Text, Image } from "@staccx/bento"
import { useTicket } from "../../context/TicketProvider"

const GameTicketListItem = ({ ticket, game, hideReceipt, index }) => {
  const { player } = usePlayer()
  const { holdTicket, tickets } = useTicket()

  const handleClick = () => {
    const hasMax =
      game.tickets.filter(
        t => player && t.player && t.player._id === player._id
      ).length >= game.settings.maxTicketsPerPlayer
    if (hasMax) {
      return
    }
    if (!ticket.owner && game.isOpen) {
      holdTicket(ticket, game)
    }
  }
  return (
    <Box
      active={tickets.some(t => t._id === ticket._id)}
      owned={ticket?.player?._ref === player?._id}
      onClick={handleClick}
      variant={["ticket"]}
      color={ticket.color || "fff"}
      index={index}
    >
      <Image
        variant={["rounded", "center"]}
        round
        src={ticket?.player?.picture}
      />
      <Heading variant="ticket">{ticket.title}</Heading>
      <Text variant="ticket">{`#${ticket.iteration}`}</Text>

      {!hideReceipt && (
        <Box active={!!ticket.player} variant="purchaser">
          {ticket.player && (
            <Text variant="purchaser">{`Kjøpt av ${ticket.player.nickname}`}</Text>
          )}
        </Box>
      )}
    </Box>
  )
}

export default GameTicketListItem
