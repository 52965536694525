import React from "react"
import styled, { css } from "styled-components"
import {
  Anchor,
  Heading,
  Box,
  Text,
  theming,
  Image,
  sanity
} from "@staccx/bento"
import { Link } from "react-router-dom"
import { imageUrlFor } from "../../api/client"

const GroupsListItemSmall = ({ group }) => {
  return (
    <Box variant="ui" as="article" size="flush">
      <Inner>
        <Anchor as={Link} to={`${group._id}`}>
          <Header>
            <Image variant={["game"]} src={imageUrlFor(group.image)} />
            <Center>
              <DocumentTitle level={5} as="h1">
                {group.name}
              </DocumentTitle>
              {group.description && (
                <sanity.BlockContent blocks={group.description} />
              )}
            </Center>
          </Header>
          <Text variant={["gray", "alignCenter"]}>{group.members}</Text>
        </Anchor>
      </Inner>
    </Box>
  )
}

const Center = styled.div`
  display: flex;
  align-items: center;
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 0.7fr auto 44px;
  grid-column-gap: 1rem;
  align-items: center;

  padding: ${theming.spacing("small")};
  ${p =>
    !p.isRead &&
    css`
      border-left: 4px solid ${theming.color("approved")};
    `}
`

const Header = styled.header`
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-column-gap: ${theming.spacing("small")};
`
//
// const IconCircle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: ${theming.spacing("mediumPlus")};
//   width: ${theming.spacing("mediumPlus")};
//   padding: ${theming.spacing("tiny")};
//   background-color: ${p =>
//     p.isRead ? theming.color("text") : theming.color("approved")};
//   border-radius: 50%;
//   color: ${theming.color("white")};
//   align-self: center;
// `

const DocumentTitle = styled(Heading)`
  line-height: 1;
`

export default GroupsListItemSmall
