import React from "react"
import { Box, Heading, Image, Text } from "@staccx/bento"

const AnimatedGameTicketListItem = ({
  ticket,
  isCurrent,
  winner,
  index = 0
}) => {
  return (
    <Box
      active={isCurrent}
      winner={winner}
      variant={["ticket"]}
      color={ticket.color || "fff"}
      index={index}
    >
      <Image
        variant={["rounded", "center"]}
        round
        src={ticket?.player?.picture}
      />
      <Heading variant="ticket">{ticket.title}</Heading>
      <Text variant="ticket">{`#${ticket.iteration}`}</Text>
      <Box active={!!ticket.player} variant="purchaser">
        {ticket.player && (
          <Text variant="purchaser">{`${ticket.player.nickname}`}</Text>
        )}
      </Box>
    </Box>
  )
}

export default AnimatedGameTicketListItem
