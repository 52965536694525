import { client } from "./client"
import { createPlayer } from "./api"
import { subToId } from "../utils/subToId"

export const getOrCreatePlayer = async user => {
  if (!user) {
    return Promise.reject()
  }

  const player = await getDocument(subToId(user.sub))
  if (player) {
    return Promise.resolve(player)
  }
  return createPlayer(user)
}

export const getGames = player => {
  if (!player) {
    return client.fetch(
      `*[_type == "game" && !defined(group)] | order(starts asc)`
    )
  }
  //* [_type == "game" && (!defined(group) || $member in group->members[]._ref)] {...}
  return client.fetch(
    `*[_type == "game" && (!defined(hidden) || hidden == false) && (!defined(group) || $member == group->owner._ref || $member in group->members[].player._ref) ] {group->{name, members}, ...} | order(starts desc)`,
    {
      member: player._id
    }
  )
}

export const getGroups = player => {
  if (!player) {
    return client.fetch(
      `*[_type == "group"] {"members": count(members) + 1, name, ...}`
    )
  }

  return client.fetch(
    `*[_type == "group" && (owner._ref == $player || $player in members[].player->_id)] {"members": count(members) + 1, ...}`,
    { player: player._id }
  )
}

export const getGroup = async (id, player) => {
  return client.fetch(
    `*[_type == "group" && _id == $id][0]{owner->, members[]{player->, ...}, ...}`,
    {
      id
    }
  )
}

export const getGame = id =>
  client.fetch(
    `*[_type == "game" && _id == $id][0]
{
"isOpen": status == "open",
"results": *[_type=="gameResult" && references(^._id)] | order(place desc) {"ticket": ticket->{"player": owner->{...}, 
"title": coalesce(title, "no title"), ...}, place, ...}, 
"tickets": *[_type=="ticket" && references(^._id)] | order(iteration asc) {"player": owner->{...}, "title": coalesce(title, "no title"), ...}, 
 ...}`,
    { id }
  )

export const getTickets = id =>
  client.fetch(
    `*[_type == "ticket" && references($game)] {"isWinner": defined(*[_type == "gameResult" && references(^._id)]), "price":*[_type == "gameResult" && references(^._id)][0].price, "player": owner->,  ...} | order(isWinner desc)`,
    { game: id }
  )

export const getDocument = id => client.getDocument(id)
