import { Box, theming } from "@staccx/bento"
import { css } from "styled-components"
import { boxShadow } from "../properties/style"

Box.createVariants({
  box: {
    ui: css`
      background-color: ${theming.color("white")};
      box-shadow: ${boxShadow};
    `,
    games: css`
      min-height: calc(100vh - 168px);
      background-color: ${theming.color`primary`};
    `,
    card: css`
      position: relative;
      max-width: 410px;
      background-color: ${theming.color`white`};
      border-radius: 6px;
      padding: 6px;
      margin: 24px;
      box-shadow: 0px 2px 8px -2px black;
    `,
    small: css`
      max-width: 224px;
    `,
    big: css`
      max-width: 300px;
      height: auto;
    `,
    pad: css`
      padding-top: ${p => p.top ?? null};
      padding-bottom: ${p => p.bottom ?? null};
      padding-left: ${p => p.left ?? null};
      padding-right: ${p => p.right ?? null};
    `,
    flex: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    horz: css`
      flex-direction: row;
    `,
    space: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
    game: css`
      background-color: ${theming.color`white`};
    `,
    prices: css``,
    wrap: css`
      flex-wrap: wrap;
    `,
    ticketsFlex: css`
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    `,
    tickets: css`
      display: grid;
      grid-template-columns: repeat(18, 1fr);
      grid-gap: 24px;

      @media (max-width: 3000px) {
        grid-template-columns: repeat(15, 1fr);
      }

      @media (max-width: 2550px) {
        grid-template-columns: repeat(12, 1fr);
      }

      @media (max-width: 2200px) {
        grid-template-columns: repeat(10, 1fr);
      }

      @media (max-width: 1450px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (max-width: 900px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (max-width: 720px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
      }
    `,

    ticket: css`
      margin: 0 12px;
      border-radius: ${theming.borderRadius};
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      align-items: center;
      height: 209px;
      width: 120px;
      background-color: #${({ color }) => color};
      transition: transform 0.5s cubic-bezier(0, 0, 0.58, 1);
      user-select: none;
      cursor: pointer;
      ${p =>
        p.active
          ? css`
              transform: scale(1.1);
            `
          : css`
              transform: scale(1);
            `};
      ${p =>
        p.winner
          ? css`
              transform: scale(0.75);
            `
          : null};
      ${p =>
        p.winner
          ? css`
              filter: drop-shadow(3px 3px 10px ${theming.color`primary`});
            `
          : null};
    `,
    purchaser: css`
      position: absolute;
      height: 209px;
      width: 120px;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
      display: ${p => (p.active ? "inherit" : "none")};
    `,
    grace: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 48px;
    `,
    results: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      @media (max-width: 720px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 400px) {
        grid-template-columns: 1fr;
      }
    `,
    status: css`
      background-color: ${theming.mapProp("status", {
        draft: css`
          ${theming.color`warning`}
        `,
        open: css`
          ${theming.color`primary`}
        `,
        locked: css`
          ${theming.color`red`}
        `,
        started: css`
          ${theming.color`approved`}
        `,
        ended: css`
          ${theming.color`blue`}
        `
      })};
      color: white;
      bottom: 0;
      width: 100%;
      text-align: center;
      border-radius: 6px;
      padding: 0 0;
    `
  }
})
