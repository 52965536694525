import React from "react"
import { Anchor, Box, Image, Text } from "@staccx/bento"
import { Link } from "react-router-dom"
import { imageUrlFor } from "../../api/client"

const GroupsItem = ({ group }) => {
  return (
    <Anchor as={Link} to={`/groups/${group._id}`}>
      <Box variant="card">
        <Image variant={["game"]} src={imageUrlFor(group.image)} />
        <Box variant={["space"]}>
          <Text variant={["bold"]}>{group.name}</Text>
        </Box>
        <Text>{group?.members}</Text>
      </Box>
    </Anchor>
  )
}

export default GroupsItem
