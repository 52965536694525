import useSwr from "swr"
import { useAuth0 } from "../auth0"
import { getOrCreatePlayer } from "../api/sanity"

export const usePlayer = () => {
  const { user } = useAuth0()
  const { data: player, error } = useSwr(user ? "/games" : null, () =>
    getOrCreatePlayer(user)
  )
  return { player, error }
}
