import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Auth0Provider } from "./auth0"
import config from "./auth_config.json"
import { useNavigate } from "react-router"
import { BrowserRouter as Router } from "react-router-dom"

const Wrapper = () => {
  const navigate = useNavigate()
  const onRedirectCallback = appState => {
    navigate(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  )
}

ReactDOM.render(
  <Router>
    <Wrapper />
  </Router>,
  document.getElementById("root")
)
