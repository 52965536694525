import React from "react"
import { mutate } from "swr"
import { Button, Modal } from "@staccx/bento"
import {
  drawRandom,
  endGame,
  lockGame,
  openGame,
  startGame
} from "../../api/api"
import GameStatus from "./Game.Status"

const GameControls = ({ game, player }) => {
  const [confirm, setConfirm] = React.useState(false)

  const refresh = () => {
    mutate(`game/${game._id}`)
  }

  const handleOpen = () => {
    openGame(player, game).then(refresh)
  }

  const handleLock = () => {
    lockGame(player, game).then(refresh)
  }

  const handleStart = () => {
    startGame(player, game).then(refresh)
  }

  const handleDrawTicket = () => {
    drawRandom(game)
      .then(refresh)
      .then(() => {
        setConfirm(false)
      })
  }

  const handleEndGame = () => {
    endGame(player, game).then(refresh)
  }

  if (!game || !player) {
    return null
  }
  if (!game.owner) {
    return null
  }
  if (game.owner._ref !== player._id) {
    return null
  }

  if (!game.status) {
    return <div>This game has no status!</div>
  }
  let content = null
  switch (game.status) {
    case "draft":
      content = (
        <Button variant="full" onClick={handleOpen}>
          Open game for purchase
        </Button>
      )
      break
    case "open":
      content = (
        <Button variant="full" onClick={handleLock}>
          Lock game and prepare for start
        </Button>
      )
      break
    case "locked":
      content = (
        <Button variant="full" onClick={handleStart}>
          Start game
        </Button>
      )
      break
    case "started":
      content = (
        <div>
          <Button variant="full" onClick={handleDrawTicket}>
            Draw ticket
          </Button>
          <Button variant="full" onClick={() => setConfirm(true)}>
            End game
          </Button>
        </div>
      )
      break
    case "ended":
      content = null
      break
    default:
      content = null
      break
  }

  return (
    <div>
      <Modal isOpen={confirm}>
        <>
          <h2>Are you sure?</h2>
          <Button onClick={handleEndGame}>Yes</Button>
          <Button onClick={() => setConfirm(false)}>Noe</Button>
        </>
      </Modal>
      <GameStatus game={game} />
      {content}
    </div>
  )
}

export default GameControls
