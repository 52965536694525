import React from "react"
import useSwr from "swr"
import { getGame } from "../../../api/sanity"
import { useParams } from "react-router"
import { Box, Heading, Layout } from "@staccx/bento"
import { usePlayer } from "../../../hooks/usePlayer"
import GameTicketList from "./../Game.TicketList"
import GamePrices from "./../Game.Prices"

const GameOpen = () => {
  const { id } = useParams()
  const { data: game, error } = useSwr(`game/${id}`, () => getGame(id), {
    refreshInterval: 2000
  })
  const { player } = usePlayer()

  return (
    <Box variant="game">
      <Layout variant="flex">
        <GamePrices prices={game.prices} />
        <Heading
          level={2}
          variant="grace"
        >{`Maks. ${game.settings.maxTicketsPerPlayer} lodd per spiller`}</Heading>
        <GameTicketList game={game} player={player} />
        {error && error.message}
      </Layout>
    </Box>
  )
}

export default GameOpen
