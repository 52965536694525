import { theming } from "@staccx/bento"

theming.theme.append({
  color: {
    bg: "#F6F8FA",
    text: "#393A41",
    red: "#C60C30",
    redDarker: "#B70B2D",
    primary: "#AD362B",
    black: "#393A41",
    lightBlue: "#F6F8FA",
    approved: "#468330",
    warning: "#F9C50A",
    line: "#306BA1",
    link: "#306BA1",
    links: "#306BA1",
    blue: "#306BA1",
    blue80: "#5C8AB5",
    blue60: "#88AAC9",
    blue40: "#B4C9DC",
    blue20: "#E0E8F0",
    black100: "#000",
    black87: "#222222",
    black70: "#4B4B4B",
    black55: "#737373",
    black28: "#9C9C9C",
    black13: "#C4C4C4",
    black9: "#E5E5E5",
    black7: "#EDEDED",
    white: "#ffffff",
    wcag: "#767676",
    darkBg: "#4d4f53",
    darkerBg: "#343639",
    positive: "#468330"
  }
})
