import React from "react"
import { Box, useInterval } from "@staccx/bento"
import useSwr from "swr"
import { useParams } from "react-router"
import AnimatedGameTicketListItem from "./Game.Started.TicketListItem"
import { getTickets } from "../../api/sanity"

const AnimatedGameTicketList = ({ game }) => {
  const { id } = useParams()
  const { data: tickets, error } = useSwr(
    `tickets/${id}`,
    () => getTickets(id),
    { refreshInterval: 1000 }
  )
  const i = React.useRef(0)

  useInterval(() => {
    i.current = Math.floor(Math.random() * tickets.length)
  }, 1000)
  return (
    <div>
      <Box variant={["flex", "pad"]} top="1rem">
        <Box variant={["tickets"]}>
          {tickets.map((ticket, index) => (
            <AnimatedGameTicketListItem
              game={game}
              key={ticket._id}
              ticket={ticket}
              winner={ticket.isWinner}
              isCurrent={i.current === index}
              index={index}
            />
          ))}
        </Box>
      </Box>
      {error && error.message}
    </div>
  )
}

export default AnimatedGameTicketList
