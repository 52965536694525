import React from "react"
import useSwr from "swr"
import { getGame } from "../../api/sanity"
import { useParams } from "react-router"
import { Box, Layout } from "@staccx/bento"
import GameDraft from "./StatusPages/Game.Draft"
import GameOpen from "./StatusPages/Game.Open"
import GameGrace from "./StatusPages/Game.Grace"
import GameStarted from "./StatusPages/Game.Started"
import GameEnded from "./StatusPages/Game.Ended"
import GameControls from "./Game.Control"
import { usePlayer } from "../../hooks/usePlayer"

const Game = () => {
  const { id } = useParams()
  const { data: game, error } = useSwr(`game/${id}`, () => getGame(id), {
    refreshInterval: 10000
  })
  const { player } = usePlayer()
  const [lastStatus, setLastStatus] = React.useState(null)
  const [content, setContent] = React.useState(null)

  React.useEffect(() => {
    if (game) {
      if (game.status !== lastStatus) {
        let c = null
        switch (game.status) {
          case "draft":
            c = <GameDraft />
            break
          case "open":
            c = <GameOpen />
            break
          case "locked":
            c = <GameGrace />
            break
          case "started":
            c = <GameStarted />
            break
          case "ended":
            c = <GameEnded />
            break
          default:
            c = null
            break
        }
        setContent(c)
        setLastStatus(game.status)
      }
    }
  }, [game, lastStatus])

  return (
    <Box variant="game">
      <Layout variant="flex">
        {content}
        <GameControls player={player} game={game} />
        {error && error.message}
      </Layout>
    </Box>
  )
}

export default Game
