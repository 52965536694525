import { Button } from "@staccx/bento"
import { css } from "styled-components"

Button.createVariants({
  button: {
    full: css`
      width: 100%;
      margin: 12px;
    `
  }
})
