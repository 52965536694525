import React from "react"
import useSwr from "swr"
import { getGame } from "../../../api/sanity"
import { useParams } from "react-router"
import { Box, formatting, Heading, Layout } from "@staccx/bento"
import GamePrices from "./../Game.Prices"

const GameDraft = () => {
  const { id } = useParams()
  const { data: game, error } = useSwr(`game/${id}`, () => getGame(id))

  return (
    <Box variant="game">
      <Layout variant="flex">
        <GamePrices prices={game.prices} />
        <Heading level={2} variant="ticket">
          Lotteriet har ennå ikke startet
        </Heading>
        <Heading level={3} variant="ticket">
          Starter om {formatting.formatDateDistance(game.starts)}
        </Heading>
        {error && error.message}
      </Layout>
    </Box>
  )
}

export default GameDraft
