import React from "react"
import { Box, Image, Text, theming } from "@staccx/bento"
import styled from "styled-components"

const GroupDetailMember = ({ member }) => {
  return (
    <Box variant="ui" as="article" size="flush">
      <Inner>
        <Image src={member.player.picture} />
        <Text>{member.player.nickname}</Text>
        <Text>{member.role}</Text>
      </Inner>
    </Box>
  )
}
export const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  align-items: center;
  text-align: right;
  padding: ${theming.spacing("small")};
`

export default GroupDetailMember
