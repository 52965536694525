import React from "react"
import useSwr from "swr"
import { getGame } from "../../../api/sanity"
import { useParams } from "react-router"
import { Box, Layout } from "@staccx/bento"
import AnimatedGameTicketList from "../Game.Started.TicketList"

const GameStarted = () => {
  const { id } = useParams()
  const { data: game, error } = useSwr(`game/${id}`, () => getGame(id), {
    refreshInterval: 1000
  })

  console.log("rendering?")
  return (
    <Box variant="game">
      <Layout variant="flex">
        <AnimatedGameTicketList game={game} />
        {error && error.message}
      </Layout>
    </Box>
  )
}

export default GameStarted
