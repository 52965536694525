import React from "react"
import { Box, Heading } from "@staccx/bento"
import GameTicketListItem from "./Game.TicketListItem"
import { usePlayerTickets } from "../../context/TicketProvider"

const GamePlayerTickets = ({ game, hideReceipt = false }) => {
  const [playerTickets] = usePlayerTickets(game)
  return (
    <Box variant="grace">
      <Heading level={3} variant="mine">
        Dine lodd
      </Heading>
      <Box variant={["ticketsFlex"]} items={playerTickets.length}>
        {playerTickets.map(ticket => (
          <GameTicketListItem
            hideReceipt={hideReceipt}
            game={game}
            key={ticket._id}
            ticket={ticket}
          />
        ))}
      </Box>
    </Box>
  )
}

export default GamePlayerTickets
