import React from "react"
import styled from "styled-components"
import { Wrapper, Box } from "@staccx/bento"
import { useGames } from "../../hooks/useGames"
import GamesList from "./Games.List"

const Games = () => {
  const { games, error } = useGames()

  return (
    <Box variant="games">
      <Wrapper>
        <Centered>
          <GamesList games={games} />
          {error && error.message}
        </Centered>
      </Wrapper>
    </Box>
  )
}

const Centered = styled.div`
  width: 100%;
  margin: 0 auto;
`

export default Games
