import React from "react"
import { sanity, Box, Image, Layout, Text } from "@staccx/bento"
import { usePlayer } from "../../hooks/usePlayer"
import { useGroup } from "../../hooks/useGroups"
import { useParams } from "react-router"
import { imageUrlFor } from "../../api/client"
import GroupDetailMember, { Inner } from "./Group.Detail.Member"

const GroupDetail = () => {
  const { player } = usePlayer()
  const { id } = useParams()
  const { group } = useGroup(id, player)

  // const isAdmin = React.useMemo(() => {
  //   return (
  //     group?.owner?._ref === player?._id ||
  //     group?.members?.some(
  //       member => member.player._ref === player?._id && member.role === "admin"
  //     )
  //   )
  // }, [player, group])

  return (
    <Box variant="flex">
      <Box variant="card">
        <Layout>
          <Image variant={["game"]} src={imageUrlFor(group.image)} />
          <Box variant={["space"]}>
            <Text variant={["bold"]}>{group.name}</Text>
          </Box>
          <Text>
            <sanity.BlockContent blocks={group.description} />
          </Text>
          <Box variant="ui" as="article" size="flush">
            <Inner>
              <Image src={group?.owner?.picture} />
              <Text>{group?.owner?.nickname}</Text>
              <Text>Owner</Text>
            </Inner>
          </Box>
          <Layout as="ul" rowGap="tiny" paddingBottom="small">
            {group?.members?.map(member => (
              <GroupDetailMember key={member._key} member={member} />
            ))}
          </Layout>
        </Layout>
      </Box>
    </Box>
  )
}

export default GroupDetail
