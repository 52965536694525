import { Heading, theming } from "@staccx/bento"
import { css } from "styled-components"

Heading.createVariants({
  heading: {
    center: css`
      text-align: center;
    `,
    logo: css`
      font-size: 2rem;
      text-align: center;
      font-family: Lobster, cursive;
      color: ${theming.color`white`};
    `,
    ticket: css`
      font-weight: 500;
      font-size: 0.7rem;
      overflow: hidden;
      padding: 0 6px;
      color: ${theming.color`text`};
      text-align: center;
    `,
    grace: css`
      font-size: 1.5rem;
      text-align: center;
      color: ${theming.color`text`};
      padding: 24px 0;

      @media (max-width: 400px) {
        font-size: 1rem;
      }
    `
  }
})
